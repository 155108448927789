<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>Cashout Management</v-toolbar-title>
    </v-app-bar>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Filter Options</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      v-model="selectedStatus"
                      :items="statusOptions"
                      label="Select Status"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="selectedCashout"
                      :items="cashoutOptions"
                      label="Select Cashout"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="email"
                      label="Email"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn @click="filterData" color="primary">Filter</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <v-expansion-panel>
              <v-expansion-panel-header
                >Export Options</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12">
                    <v-card-text class="info-text">
                      <ul>
                        <li>We have two types of cashout options:</li>
                        <ul>
                          <li>PayPal</li>
                          <li>Bank Transfer</li>
                        </ul>
                      </ul>
                      <p>
                        There are two buttons:
                        Export PayPal and Export Bank Transfer.
                      </p>
                      <p>
                        After either button is clicked, a file will be sent to
                        the admin system with the details. Bank Account is disabled for now
                      </p>
                      <p style="color: red;">
                      Note: As soon as the file is exported the payment status will be changed to Credited. So make sure to do the payment the same time.
                      </p>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      @click="openExportDialog('PayPal')"
                      color="secondary"
                      class="mr-4"
                      >Export PayPal</v-btn
                    >
                    <v-btn
                      @click="openExportDialog('Bank Transfer')"
                      color="secondary"
                      disabled
                      >Export Bank Transfer</v-btn
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mt-4"
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="filteredCashout"
            :search="search"
            class="elevation-1 mt-4"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Confirm Export</v-card-title>
        <v-card-text
          >Are you sure you want to export {{ exportType }} data?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="confirmExport"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";
const service = new RestResource();

export default {
  data() {
    return {
      cashout: [],
      filteredCashout: [],
      headers: [
        { text: "Email", value: "email" },
        { text: "Points Redeemed", value: "pointsRedeemed" },
        { text: "Status", value: "status" },
        { text: "Cashout Type", value: "cashoutType" },
        { text: "Date", value: "createdAt" },
      ],
      search: "",
      email: "",
      selectedStatus: "IN PROGRESS",
      selectedCashout: "",
      statusOptions: ["IN PROGRESS", "CREDITED", "REJECTED"],
      cashoutOptions: ["PAYPAL", "BANK TRANSFER"],
      dialog: false,
      exportType: "",
    };
  },

  mounted() {
    this.cashoutData();
  },

  methods: {
    cashoutData() {
      this.$setLoader();
      const params = {};
      if (this.selectedStatus) {
        params.status = this.selectedStatus;
      }
      if (this.email) {
        params.email = this.email;
      }
      if(this.selectedCashout) {
        params.cashoutType = this.selectedCashout
      }
      service
        .getCashoutData(params)
        .then((r) => {
          this.cashout = r.data;
          this.filteredCashout = r.data;
          this.$disableLoader();
        })
        .catch(() => {
          this.$disableLoader();
        });
    },
    filterData() {
      this.cashoutData(); // Fetch data from backend with the selected filters
    },
    openExportDialog(type) {
      this.exportType = type;
      this.dialog = true;
    },
    confirmExport() {
      if (this.exportType === "PayPal") {
        this.exportPayPal();
      } else if (this.exportType === "Bank Transfer") {
        this.exportBankTransfer();
      }
      this.dialog = false;
    },
    exportPayPal() {
      this.$setLoader()
      service.cashoutDataExport({ type: "PayPal" }).then(() => {
        this.$disableLoader();
        this.cashoutData();
      });
      console.log("Exporting PayPal data");
    },
    exportBankTransfer() {
      // Implement export logic for Bank Transfer
      console.log("Exporting Bank Transfer data");
    },
  },
};
</script>

<style scoped>
.v-app {
  background-color: #f5f5f5;
}

.v-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.v-app-bar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.v-expansion-panel-header {
  background-color: #e0e0e0;
}

.v-expansion-panel-content {
  background-color: #ffffff;
}

.v-data-table {
  background-color: #ffffff;
  border-radius: 8px;
}

.info-text {
  font-size: 16px;
  line-height: 1.5;
  color: #424242;
}

.info-text ul {
  margin-bottom: 16px;
}

.info-text p {
  margin-bottom: 16px;
}
</style>
